import { Autocomplete, FormControl, TextField, Typography } from "@mui/material"
import classNames from "classnames"
import { useFormikContext } from "formik"
import { CourseFormValues } from "../types"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { updateRelatedSearch } from "../activeCourseSlice"

export const RelatedCoursesField = () => {
  const { values, setFieldValue } = useFormikContext<CourseFormValues>()

  const dispatch = useAppDispatch()
  const { relatedSearchResults, relatedSearch } = useAppSelector(
    (state) => state.activeCourse
  )
  return (
    <FormControl
      variant="standard"
      className={classNames("inputSelect relatedCourses")}
    >
      <Typography
        component="label"
        htmlFor="related-courses-select"
        variant="h3"
        gutterBottom={true}
      >
        Related Courses
      </Typography>
      <Autocomplete
        multiple
        id="related-courses-select"
        noOptionsText={
          relatedSearch ? "Getting courses…" : "Start typing to search…"
        }
        options={relatedSearchResults}
        value={values.relatedCourses ?? relatedSearch}
        getOptionLabel={(option) => option.name ?? relatedSearch}
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            value={relatedSearch}
            onChange={(event) =>
              dispatch(updateRelatedSearch(event.target.value))
            }
            placeholder="Search for courses"
          />
        )}
        onChange={(event, values) => {
          setFieldValue("relatedCourses", values)
        }}
      />
    </FormControl>
  )
}
