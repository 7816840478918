import { useCallback } from "react"
import { useAppDispatch } from "store/hooks"
import {
  FormControl,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"
import classNames from "classnames"
import _ from "lodash"
import Autocomplete from "@mui/material/Autocomplete"
import { Tabs, Tab } from "@mui/material"

import {
  setLearningPath,
  setSearchQuery,
  setSearchInput,
  setCategory,
  setCurrentTab,
  setReviewType,
  setSortBy,
  resetFilters,
  setGroup,
} from "./reviewSlice"

import "../../styles/sidebar.scss"
import CategorySelect from "@mobilemind/common/src/components/CategorySelect"

let extractColumn = (arr, column) => arr.map((x) => x[column])

function SidebarReview(props) {
  const dispatch = useAppDispatch()
  const { categories, session } = props
  const { groupRoles, orgRoles } = session

  const roles = extractColumn(session.roles, "target_id")
  !session.subGroups.fetched &&
    props.session.group &&
    props.getSubGroups(props.session.group.uuid[0].value)

  const { currentTab, reviewType } = props.assessments

  const { learningPath, searchInput, category, group, sortBy } =
    props.assessments.filters

  const isOrgAdmin = orgRoles.includes("organization-admin")
  const isOrgReviewer = orgRoles.includes("organization-reviewer")
  const isGroupAdmin = groupRoles.includes("group-admin")
  const isGroupReviewer = groupRoles.includes("group-reviewer")
  const isOrgLevel = isOrgAdmin || isOrgReviewer
  const isGroupLevel = isGroupAdmin || isGroupReviewer
  const isSiteReviewer =
    roles.includes("administrator") ||
    roles.includes("reviewer") ||
    roles.includes("tier_1_grader")
  const isGroupFilter = !isSiteReviewer && (isOrgLevel || isGroupLevel)
  const groupType = isSiteReviewer
    ? ""
    : isOrgAdmin
    ? "Org"
    : isGroupAdmin
    ? "Group"
    : ""

  let activeLearningPaths = props.learningPaths.data.filter((path) => {
    if (!path.attributes.field_draft && !path.attributes.field_archive) {
      if (session.isPartner) {
        return (
          path.relationships.field_partner.data?.id ===
          session.group.uuid[0].value
        )
      } else {
        return path
      }
    }
    return false
  })

  const debounceOnChange = useCallback(_.debounce(updateQuery, 1000), []) // eslint-disable-line react-hooks/exhaustive-deps

  function updateQuery(value) {
    dispatch(setSearchQuery(value))
  }

  function handleChange(value) {
    dispatch(setSearchInput(value))
    debounceOnChange(value)
  }

  const subgroupItems = isOrgLevel
    ? session.subGroups.data.map((subGroup) => {
        return (
          <MenuItem key={subGroup.id} value={subGroup.entity_id}>
            {subGroup.attributes.label}
          </MenuItem>
        )
      })
    : isGroupLevel
    ? session.subgroups.data.map((subGroup) => {
        return (
          <MenuItem key={subGroup.id} value={subGroup.drupal_internal__id}>
            {subGroup.attributes.label}
          </MenuItem>
        )
      })
    : null

  return (
    <div id="sidebar">
      <div id="tabs-assessment-list">
        <Tabs
          value={currentTab}
          onChange={(event, newValue) => dispatch(setCurrentTab(newValue))}
        >
          <Tab value="review" label="Review" />
          <Tab value="reviewed" label="Reviewed by You" />
        </Tabs>
      </div>
      <form className="filters">
        <header>
          <strong>Filter</strong>
          <Button
            className="button small"
            onClick={() => {
              dispatch(resetFilters())
            }}
          >
            Reset
          </Button>
        </header>

        {
          <FormControl
            variant="standard"
            style={{ marginBottom: -10, marginTop: 15 }}
            className={classNames("inputSelect learningPath")}
          >
            <Autocomplete
              id="learningPath-select"
              variant="standard"
              noOptionsText={"Getting learning paths..."}
              options={activeLearningPaths}
              value={learningPath ? learningPath : "none"}
              getOptionLabel={(learningPath) =>
                learningPath.attributes
                  ? learningPath.attributes.name
                  : "Search for Learning Path"
              }
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Learning Path"
                />
              )}
              onChange={(event, newValue) =>
                dispatch(setLearningPath(newValue))
              }
            />
          </FormControl>
        }

        <div
          className="flexRow inputSearch"
          style={{ marginTop: 20, marginBottom: 25 }}
        >
          <span className="icon search" />
          <TextField
            variant="standard"
            label="Search"
            value={searchInput}
            onChange={(event) => handleChange(event.target.value)}
          />
        </div>

        <CategorySelect
          currentValue={category}
          categories={categories}
          label={"Category"}
          allowedLevels={3}
          onChange={(newCategory) => dispatch(setCategory(newCategory))}
        />

        {isGroupFilter && (
          <FormControl variant="standard" className="inputSelect group">
            <InputLabel id="label-group-select">Group</InputLabel>
            <Select
              variant="standard"
              labelId="label-group-select"
              id="group-select"
              value={group}
              onChange={(event) => dispatch(setGroup(event.target.value))}
            >
              <MenuItem value={"All"}>All</MenuItem>
              {subgroupItems}
            </Select>
          </FormControl>
        )}
        {(isOrgAdmin || isGroupAdmin) && currentTab === "review" && (
          <FormControl variant="standard" className="inputSelect admin">
            <InputLabel id="label-review-type-select">Reviewers</InputLabel>
            <Select
              variant="standard"
              labelId="label-admin-select"
              id="admin-select"
              value={reviewType}
              onChange={(event) => dispatch(setReviewType(event.target.value))}
            >
              <MenuItem value={"reviewer"}>Assigned to You</MenuItem>
              <MenuItem value={"admin"}>All {groupType} Challenges</MenuItem>
            </Select>
          </FormControl>
        )}

        <header>
          <strong>Sort By</strong>
        </header>

        <FormControl
          variant="standard"
          style={{ marginTop: 15 }}
          className="inputSelect"
        >
          <InputLabel style={{ paddingRight: 5, textTransform: "none" }}>
            Sort By
          </InputLabel>
          <Select
            variant="standard"
            value={sortBy}
            onChange={(event) => {
              dispatch(setSortBy(event.target.value))
            }}
          >
            <MenuItem value={"changed"}>Date Submitted</MenuItem>
            <MenuItem value={"course_title"}>Course Title</MenuItem>
          </Select>
        </FormControl>
      </form>
    </div>
  )
}

export default SidebarReview
